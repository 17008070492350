import * as ActionTypes from '../action-types';

const initialState = {
    emailDefault: {
        "request_review": {
            "id": 7,
            "name": "request_review",
            "event": "fulfillment",
            "delay_time": 7,
            "delay_time_type": "day",
            "is_active": true,
        },
        "request_review_reminder": {
            "id": 8,
            "name": "request_review_reminder",
            "event": "after_request_review",
            "delay_time": 7,
            "delay_time_type": "day",
            "is_active": true,
        },
        "thank_you_for_review": {
            "id": 9,
            "name": "thank_you_for_review",
            "event": "approve_for_review",
            "delay_time": 0,
            "delay_time_type": "day",
            "is_active": true,
        }
    },
    emailTemplate: []
}

const setEmailDefault = (state, payload) => {
    return {
        ...state,
        emailDefault: payload,
    };
};

const updateEmailTemplate = (state, payload) => {
    return {
        ...state,
        emailTemplate: payload,
    };
};

const Email = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case ActionTypes.SET_EMAIL_DEFAULT:
            return setEmailDefault(state, payload);
        case ActionTypes.UPDATE_EMAIL_TEMPLATE:
            return updateEmailTemplate(state, payload);
        default:
            return state;
    }
}

export default Email
import axios from "axios";
import store from "../store";
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            store.dispatch({
                type: "AUTH_LOGOUT",
            });
        }
        return Promise.reject(error);
    }
);
export default axios;

import * as ActionTypes from '../action-types';

const initialState = {
    data: [],
}

const setListEmail = (state, payload) => {
    return {
        ...state,
        data: payload
    }
}

const updateOrderEmail = (state, payload) => {
    const updatedOrders = (state.data || []).map(order => {
        if (order.id === payload.orderId) {
            return {
                ...order,
                email: payload.newEmail,
                customer: {
                    ...order.customer,
                    email: payload.newEmail,
                },
            };
        }
        return order;
    });

    setListEmail(state, updatedOrders);
    return {
        ...state,
        data: updatedOrders,
    };
}

const SendMailManual = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionTypes.FETCH_DATA_SUCCESS:
            return setListEmail(state, payload);
        case ActionTypes.UPDATE_ORDER_EMAIL:
            return updateOrderEmail(state, payload);
        default:
            return state;
    }
}

export default SendMailManual;
import { combineReducers } from 'redux';
import Auth from './Auth';
import Layout from "./Layout";
import Email from "./Email";
import SendMailManual from './SendMailManual';


const appReducer = combineReducers({
  Auth,
  Email,
  Layout,
  SendMailManual
});

const RootReducer = (state, action) => {
  return appReducer(state, action);
};

export default RootReducer;

import * as ActionTypes from "../action-types";

// auth
export const authLogin = (payload) => ({
    type: ActionTypes.AUTH_LOGIN,
    payload,
});

export const refreshUser = (payload) => ({
    type: ActionTypes.REFRESH_USER,
    payload,
});

export const authLogout = () => ({
    type: ActionTypes.AUTH_LOGOUT,
});

export const authCheck = () => ({
    type: ActionTypes.AUTH_CHECK,
});
export const updateShopInfo = (payload) => ({
    type: ActionTypes.UPDATE_SHOP_INFO,
    payload,
});

export const setNotiInfo = (payload) => ({
    type: ActionTypes.SET_NOTI_INFO,
    payload,
});

// language

export const localeCheck = () => ({
    type: ActionTypes.LOCALE_CHECK,
});
export const setLocale = (payload) => ({
    type: ActionTypes.SET_LOCALE,
    payload,
});

// layout

export const setTabHeader = (payload) => ({
    tab: ActionTypes.SET_TAB_HEADER,
    payload,
});

export const setEmailDefault = (payload) => ({
    type: ActionTypes.SET_EMAIL_DEFAULT,
    payload,
});

export const updateEmailTemplate = (payload) => ({
    type: ActionTypes.UPDATE_EMAIL_TEMPLATE,
    payload,
});

export const updateOrderEmail = (payload) => ({
    type: ActionTypes.UPDATE_ORDER_EMAIL,
    payload,
});
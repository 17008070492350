// auth
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const REFRESH_USER = 'REFRESH_USER';
export const UPDATE_SHOP_INFO = 'UPDATE_SHOP_INFO';

export const SET_EMAIL_DEFAULT = 'SET_EMAIL_DEFAULT';

// other
export const RESET_STORE = 'RESET_STORE';
export const SET_NOTI_INFO = 'SET_NOTI_INFO';

// locale
export const LOCALE_CHECK = 'LOCALE_CHECK';
export const SET_LOCALE = 'SET_LOCALE';

//layout
export const SET_TAB_HEADER = 'SET_TAB_HEADER';

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
export const IS_ON_BOARDING = 'IS_ON_BOARDING'

export const FETCH_DATA_SUCCESS = 'FETCH_DATA_LIST_EMAIL';

export const UPDATE_ORDER_EMAIL = 'UPDATE_ORDER_EMAIL';
import * as ActionTypes from '../action-types';

const initialState = {
    header: {
        tabHeader: 0
    }
}

const setTabHeader = (state, payload) => {
    return {
        ...state,
        header: {...state.header, ...payload},
    };
};

const Layout = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case ActionTypes.SET_TAB_HEADER:
            return setTabHeader(state, payload);
        default:
            return state;
    }
}

export default Layout
import * as ActionTypes from "../action-types";
import Http from "../../layouts/Http";
import { APP_NAME } from "../../api/Config";

const defaultUser = {
    shop_id: null,
    shop_domain: null,
    name: null,
    uuid: null,
};

const initialState = {
    access_token: null,
    isAuthenticated: false,
    user: defaultUser,
    locale: "en",
    autoSave: true,
    shopInfo: {},
};

const authLogin = (state, payload) => {
    const { access_token: AccessToken, user } = payload;
    Http.defaults.headers.common.Authorization = `${AccessToken}`;

    return {
        ...state,
        isAuthenticated: true,
        user,
        access_token: AccessToken,
    };
};

const logout = (state) => {
    return {
        ...state,
        isAuthenticated: false,
        user: defaultUser,
    };
};

const updateShopInfo = (state, payload) => {
    const data = Object.fromEntries(
        Object.entries(payload).filter(([key, value]) => key !== "app_token")
    );
    return {
        ...state,
        shopInfo: {
            ...state.shopInfo,
            ...data,
            widget: {
                ...state.shopInfo.widget,
                ...payload.widget,
            },
        },
    };
};

// const checkLocale = (state) => ({
//     ...state,
//     // locale: !localStorage.getItem(`${APP_NAME}_locale`)
//     //     ? state.locale
//     //     : localStorage.getItem(`${APP_NAME}_locale`),
// });

const setLocale = (state, payload) => {
    const { locale } = payload;
    // localStorage.setItem(`${APP_NAME}_locale`, locale);
    return {
        ...state,
        locale: locale,
    };
};

const onBoarding = (state, payload) => {
    return {
        ...state,
        shopInfo: {
            ...state.shopInfo,
            on_boarding: payload,
        },
    };
};

const Auth = (state = initialState, { type, payload = null }) => {
    switch (type) {
        // case ActionTypes.REFRESH_USER:
        //     return refreshUser(state);
        case ActionTypes.AUTH_LOGIN:
            return authLogin(state, payload);
        case ActionTypes.UPDATE_SHOP_INFO:
            return updateShopInfo(state, payload);
        // case ActionTypes.AUTH_CHECK:
        //     return checkAuth(state);
        case ActionTypes.AUTH_LOGOUT:
            return logout(state);
        // case ActionTypes.LOCALE_CHECK:
        //     return checkLocale(state);
        case ActionTypes.SET_LOCALE:
            return setLocale(state, payload);
        case ActionTypes.IS_ON_BOARDING:
            return onBoarding(state, payload);
        default:
            return state;
    }
};

export default Auth;

import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import Routers from "./Routers";
import { AppProvider, Frame } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import Menus from "./layouts/MainLayout/Menus";
import "./i18n";
import MainLayout from "./layouts/MainLayout/index";
import "./assets/scss/styles.scss";
const SignIn = lazy(() => import("./pages/SignIn"));
const urlParams = new URLSearchParams(window.location.search);
let host = urlParams.get("host");
let shop = urlParams.get("shop");
const embedded = urlParams.get("embedded");
const root = ReactDOM.createRoot(document.getElementById("root"));
const App = () => {
    return (
        <>
            <div className="trustshop-app--container">
                <div className="trustshop-app--wrap">
                    <Menus />
                    <Routers />
                </div>
            </div>
        </>
    );
};

if (embedded) {
    root.render(
        <React.Suspense fallback="loading">
            <AppProvider host={host} shop={shop}>
                <Provider store={store}>
                    <MainLayout>
                        <BrowserRouter>
                            <Frame>
                                <App />
                                <ui-save-bar id="custom-save-bar">
                                    <button
                                        variant="primary"
                                        id="save-button"
                                    ></button>
                                    <button id="discard-button"></button>
                                </ui-save-bar>
                            </Frame>
                        </BrowserRouter>
                    </MainLayout>
                </Provider>
            </AppProvider>
        </React.Suspense>
    );
} else {
    root.render(
        <React.StrictMode>
            <React.Suspense fallback="loading">
                <AppProvider>
                    <SignIn />
                </AppProvider>
            </React.Suspense>
        </React.StrictMode>
    );
}

reportWebVitals(console.log);
